import { Box } from '@material-ui/core';
import { Header } from '../../components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const IncompleteOrders = () => {
  let hasDDFOnlyStore:any = 'DubaiDutyFreeStoreType';
  const checkStoreTypes = () => {
    active_stores?.filter((store: any) => {
      if (store?.store_type !== 'DubaiDutyFreeStoreType') {
        hasDDFOnlyStore = false;
      }
    });
    return {
      DubaiDutyFreeStoreType: hasDDFOnlyStore,
    };
  };
  const active_stores = useSelector((state: any) => state.store.active_stores);
  return (
    <Box>
      <Header
        title={
          <FormattedMessage id="incompleteOrders" defaultMessage="Incomplete Orders Report" />
        }
        rightAccessory={() => {
          return <></>;
        }}
      />

      <Box component="div">
        {() => {
          checkStoreTypes();
          switch (hasDDFOnlyStore) {
            case 'DubaiDutyFreeStoreType':
              console.log('----DubaiDutyFreeStoreType');
              return (
                <iframe
                  width="100%"
                  height="800px"
                  src="https://lookerstudio.google.com/embed/reporting/2713cf6c-9c70-4ec2-a1ea-f456e5c24bfb/page/iBIpD"
                  sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
                ></iframe>
              );
            default:
              return (
                <div>
                  {' '}
                  <FormattedMessage
                    id="noReports"
                    defaultMessage="No Reports Available"
                  />
                </div>
              );
          }
        }}
      </Box>
    </Box>
  );
};

export default IncompleteOrders;
