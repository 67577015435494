import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { Header } from '../../components';
import { FormattedMessage } from 'react-intl';
import MaterialTable, { Icons } from 'material-table';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { getCurrency, getStoreByID, updateDecimals } from '../../utils';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import { cashReportApi, shiftsApi } from '../../services/cashReport';
import { get } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  bodyContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${140}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${120}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${100}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  body: {
    flex: 1,
    overflow: 'auto',
    paddingBottom: 100,
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 20,
    zIndex: 999,
    [theme.breakpoints.between('md', 'xl')]: {
      left: 350,
    },
    left: 0,
    right: 0,
  },
  detailPanelIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
const tableIcons: Icons = {
  DetailPanel: React.forwardRef((props, ref) => {
    return <></>;
  }),
};
const CashReport = () => {
  const classes = useStyles();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const store = getStoreByID(active_stores, active_stores[0].store_id);
  const { pageNumber, pageSize = 10 } = useSelector(
    (state: any) => state.orders,
  );
  const { cashReport: page = 1 } = pageNumber;
  const handlePagination = (event: any, value: any) => {
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        cashReport: value,
      },
    });
  };

  const appendCurrency = (value: any) => {
    let number = new Intl.NumberFormat('en-GB', { style: 'currency', currency: store[0].currency }).format(value);
    return number;
  };

  const formatShiftTime = (begin: string, end: string) => {
    if (begin && end)
      return `${moment(begin).utc().format('HH:mm:ss')} - ${moment(end).utc().format('HH:mm:ss')}`
    return '-';
  }

  const getDifference = (value1: string, value2: string) => {
    const difference = parseFloat(value1) - parseFloat(value2);
    return !Number.isNaN(difference) ? difference : 0.0
  }

  const fetchReportsData = async () => {
    let params: any = {
      v1: true,
      store_id: active_stores[0].store_id,
      app_clip_ids: active_stores[0].app_clip_id,
      page,
      token: cookies.app_token,
      page_size: pageSize,
    };
    return cashReportApi.get(params).then((res: any) => {
      console.log(res.data);
      return res.data || {};
    });
  };
  const { isLoading: isReportsLoading, data: reportsData } = useQuery(
    ['CashReortData', page, active_stores, pageSize],
    fetchReportsData,
    {
      refetchOnWindowFocus: false,
      enabled: active_stores.length === 1,
    },
  );

  const fetchShiftsData = async (rowData: any) => {
    let params: any = {
      v1: 'shifts_v1',
      store_id: active_stores[0].store_id,
      shift_ids: rowData.shifts_list.join(),
      token: cookies.app_token,
    };
    return shiftsApi.get(params).then((res: any) => {
      return res.data.data || {};
    });
  };

  const renderReportTable = () => {
    // TODO: to update/add render function for all the columns here for customizing response data to UI needs

    let columns: any = [
      {
        title: <FormattedMessage id="date" defaultMessage="Date" />,
        field: 'business_date',
      },
      {
        title: <FormattedMessage id="register" defaultMessage="Register" />,
        field: 'register',
      },
      {
        title: (
          <FormattedMessage id="totalSales" defaultMessage="Total Sales" />
        ),
        field: 'sales_amount',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.sales_amount),
      },
      {
        title: <FormattedMessage id="sales" defaultMessage="Sales" />,
        field: 'sales_count',
        align: 'left',
        render: (rowData: any) => rowData?.sales_count,
      },
      {
        title: <FormattedMessage id="returns" defaultMessage="Returns" />,
        field: 'returns_amount',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.returns_amount),
      },
      {
        title: <FormattedMessage id="taxes" defaultMessage="Taxes" />,
        field: 'taxes',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.taxes),
      },

      {
        title: (
          <FormattedMessage
            id="numberOfShifts"
            defaultMessage="No. of shifts"
          />
        ),
        field: 'shifts',
        render: (rowData: any) => rowData?.shifts_count
      },
      {
        title: <></>,
        field: '',
        render: (rowData: any) =>
          rowData?.isShiftsOpen ? (
            <div className={classes.detailPanelIcon}>
              <span>Close</span>
              <KeyboardArrowUp
                style={{
                  fontSize: 30,
                  color: '#ffffff',
                }}
              />
            </div>
          ) : (
            <div className={classes.detailPanelIcon}>
              <span>View shifts</span>
              <KeyboardArrowDown
                style={{
                  fontSize: 30,
                  color: '#000000',
                }}
              />
            </div>
          ),
      },
    ];
    return (
      <MaterialTable
        title=""
        columns={columns}
        data={get(reportsData, 'data', {})}
        icons={tableIcons}
        isLoading={isReportsLoading}
        components={{
          Toolbar: () => <></>,
        }}
        options={{
          paging: false,
          search: false,
          sorting: false,
          overflowY: 'auto',
          headerStyle: {
            backgroundColor: '#A6C1FF',
            height: 40,
            fontWeight: 400,
            fontSize: 13,
            color: '#222B45',
          },
          padding: 'dense',
          loadingType: 'overlay',
          detailPanelColumnAlignment: 'right',
          rowStyle: (rowData: any) => ({
            backgroundColor: rowData.isShiftsOpen ? '#222B45' : '#F7F9FC',
            height: 40,
            fontWeight: 700,
            fontSize: 12,
            color: rowData.isShiftsOpen ? '#FFFFFF' : '#222B45',
          }),
          tableLayout: 'auto',
        }}
        style={{ marginLeft: 24, marginRight: 24 }}
        onRowClick={(event, row_data: any, togglePanel: any) => {
          event.stopPropagation()
          row_data && (row_data.isShiftsOpen = !row_data.isShiftsOpen);
          togglePanel();
        }}
        detailPanel={(rowData: any) => <RenderDetailPanel rowData={rowData} />}
      />
    );
  };

  const RenderDetailPanel = (rowDataProp: any) => {
    let isShiftsLoading;
    const { rowData } = rowDataProp;
    const [shiftData, setShiftData] = useState([]);

    useEffect(() => {
      const init = async () => {
        isShiftsLoading = true;
        const data = await fetchShiftsData(rowData);
        isShiftsLoading = false;
        setShiftData(data);
      }
      init();
    }, []);
    let columns: any;
    columns = [
      {
        title: <FormattedMessage id="shiftId" defaultMessage="Shift ID" />,
        field: 'store_shift_id',
      },
      {
        title: <FormattedMessage id="Time" defaultMessage="Time" />,
        field: 'time',
        render: (rowData: any) => formatShiftTime(rowData?.start_time, rowData?.end_time),
      },
      {
        title: (
          <FormattedMessage id="startAmount" defaultMessage="Start amount" />
        ),
        field: 'startAmount',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.start_shift_amount),
      },
      {
        title: (
          <FormattedMessage
            id="totalCashCount"
            defaultMessage="Total cash taken"
          />
        ),
        field: 'totalCashCount',
        align: 'left',
        render: (rowData: any) => appendCurrency(getDifference(rowData?.sales_amount, rowData?.returns_amount))
      },
      {
        title: (
          <FormattedMessage id="salesAmount" defaultMessage="Sales amount" />
        ),
        field: 'salesAmount',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.sales_amount),
      },
      {
        title: <FormattedMessage id="sales" defaultMessage="Sales" />,
        field: 'sales',
        align: 'left',
        render: (rowData: any) => rowData?.sales_count,
      },
      {
        title: <FormattedMessage id="returns" defaultMessage="Returns" />,
        field: 'returns',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.returns_amount),
      },
      {
        title: <FormattedMessage id="taxes" defaultMessage="Taxes" />,
        field: 'taxes',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.taxes),
      },
      {
        title: (
          <FormattedMessage
            id="totalCashInTillCounted"
            defaultMessage="Total cash in till counted"
          />
        ),
        field: 'totalCashInTillCounted',
        align: 'left',
        render: (rowData: any) =>
          appendCurrency(rowData?.end_shift_till_cash_count), // end_shift_till_cash_count - check slack
      },
      {
        title: <FormattedMessage id="difference" defaultMessage="Difference" />,
        field: 'difference',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.mismatch_amount),
      },
      {
        title: <FormattedMessage id="endAmount" defaultMessage="End amount" />,
        field: 'endAmount',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.end_shift_amount),
      },
      {
        title: <FormattedMessage id="removed" defaultMessage="Removed" />,
        field: 'removed',
        align: 'left',
        render: (rowData: any) => appendCurrency(rowData?.removed_shift_amount),
      },
    ];

    return (
      <div
        style={{
          border: '1px solid #A6C1FF',
        }}
      >
        <MaterialTable
          title=""
          columns={columns}
          data={shiftData}
          icons={tableIcons}
          isLoading={isShiftsLoading}
          components={{
            Toolbar: () => <></>,
          }}
          options={{
            paging: false,
            search: false,
            sorting: false,
            overflowY: 'auto',
            headerStyle: {
              backgroundColor: '#FFFFFF',
              height: 40,
              fontWeight: 400,
              fontSize: 13,
              color: '#222B45',
              padding: 5,
            },
            padding: 'dense',
            rowStyle: {
              backgroundColor: '#FFFFFF',
              height: 40,
              fontWeight: 700,
              fontSize: 12,
              color: '#222B45',
              padding: 0,
            },
            tableLayout: 'auto',
          }}
        />
      </div>
    );

  };

  return (
    <Box className={classes.root}>
      <Header
        title={
          <FormattedMessage id="cashReport" defaultMessage="Cash Report" />
        }
        subtitle={
          <FormattedMessage id="cashReportOf" defaultMessage="Cash report of" />
        }
        rightAccessory={() => {
          return <></>;
        }}
      />
      <Box component="div" className={classes.bodyContainer}>
        {active_stores.length === 1 && get(reportsData, "data", []).length >= 1 ? (
          <>
            <Box component="div" className={classes.body}>
              {renderReportTable()}
            </Box>
            <Paper className={classes.footer}>
              <InputLabel htmlFor="age-simple" style={{ marginRight: 8 }}>
                No. of Items
              </InputLabel>
              <Select
                style={{ marginRight: 8 }}
                value={pageSize}
                onChange={(event: any) => {
                  dispatch({
                    type: 'CHANGE_ROWS_PER_PAGE',
                    payload: event.target.value,
                  });
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              <Pagination
                count={Math.ceil(get(reportsData, 'count', 10) / pageSize)}
                shape="rounded"
                size="medium"
                color="primary"
                page={page}
                onChange={handlePagination}
              />
            </Paper>
          </>
        ) : (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              backgroundColor: '#8f9bb3c2',
            }}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              {active_stores.length > 1 ? (
                <>
                  <p>Cash reports are not available</p>
                  <p>when multiple stores are selected</p>
                  <p>Please select a single store to continue</p>
                </>
              ) : (
                <FormattedMessage
                  id="noReports"
                  defaultMessage="No Reports Available"
                />
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(CashReport);
