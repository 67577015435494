import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { Header } from '../../components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const RevenueReport = () => {
  let hasMLSEOnlyStore:any = 'MLSEStoreType';
  let hasCartersOnlyStore:any = 'CartersStoreType';
  let hasDDFOnlyStore:any = 'DubaiDutyFreeStoreType';
  const checkStoreTypes = () => {
    active_stores?.filter((store: any) => {
      if (store?.store_type !== 'MLSEStoreType') {
        hasMLSEOnlyStore = false;
      }
      if (store?.store_type !== 'CartersStoreType') {
        hasCartersOnlyStore = false;
      }
      if (store?.store_type !== 'DubaiDutyFreeStoreType') {
        hasDDFOnlyStore = false;
      }
    });

    console.log('MLSEStoreType', hasMLSEOnlyStore)
    console.log('CartersStoreType', hasCartersOnlyStore)
    console.log('DubaiDutyFreeStoreType', hasDDFOnlyStore)
    return {
      MLSEStoreType: hasMLSEOnlyStore,
      CartersStoreType: hasCartersOnlyStore,
      DubaiDutyFreeStoreType: hasDDFOnlyStore,
    };
  };
  const active_stores = useSelector((state: any) => state.store.active_stores);
  return (
    <Box>
      <Header
        title={
          <FormattedMessage
            id="revenueReport"
            defaultMessage="Revenue Report"
          />
        }
        subtitle={
          <FormattedMessage
            id="revenueReportOf"
            defaultMessage="Revenue report of"
          />
        }
        rightAccessory={() => {
          return <></>;
        }}
      />

      <Box component="div">
        <div>
          {(() => {
            checkStoreTypes();
            switch (
              hasMLSEOnlyStore ||
              hasCartersOnlyStore ||
              hasDDFOnlyStore
            ) {
              case 'MLSEStoreType':
                console.log("----MLSEStoreType")
                return (
                  <iframe
                    width="100%"
                    height="800px"
                    src="https://lookerstudio.google.com/embed/reporting/b827d1e2-0617-42b8-b529-088bb4f51515/page/iBIpD"
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
                  ></iframe>
                );
              case 'CartersStoreType':
                console.log("----CartersStoreType")
                return (
                  <iframe
                    width="100%"
                    height="800px"
                    src="https://lookerstudio.google.com/embed/reporting/17481731-26bb-4f10-b965-4a98c0d6d63e/page/3fS4D"
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
                  ></iframe>
                );
              case 'DubaiDutyFreeStoreType':
                console.log("----DubaiDutyFreeStoreType")
                return (
                  <iframe
                    width="100%"
                    height="800px"
                    src="https://lookerstudio.google.com/embed/reporting/b8088510-1dcf-4863-a0a0-cdc42bf72798/page/iBIpD"
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
                  ></iframe>
                );
              default:
                <FormattedMessage
                  id="noReports"
                  defaultMessage="No Reports Available"
                />;
            }
          })()}
        </div>
      </Box>
    </Box>
  );
};

export default RevenueReport;
