import { get } from 'lodash';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const [cookies] = useCookies();
  const { active_stores } = useSelector((state: any) => state.store);

  const routeToScreens = (props: any) => {
    let hasMLSEOnlyStore = true;
    let hasCartersOnlyStore = true;
    let hasDDFOnlyStore = true;
    const path = props?.location?.pathname;
    let clickAndCollectStore = active_stores.find((store: any) =>
      get(store, 'features_applicable.click_and_collect', false),
    );
    const isClickAndCollect = get(
      clickAndCollectStore,
      'features_applicable.click_and_collect',
      false,
    );
    let hasScanAndGoStores = active_stores.some((store: any) =>
      get(store, 'features_applicable.scan_and_go', false),
    );
    let hasPromoEnabled = active_stores.some((store: any) =>
      get(store, 'features_applicable.promotion', false),
    );
    let cashDeskStore = active_stores.find((store: any) =>
      get(store, 'features_applicable.cashier_kiosk', false),
    );
    const isCashDesk = get(
      cashDeskStore,
      'features_applicable.cashier_kiosk',
      false,
    );
    let hasKioskStores = active_stores.some((store: any) =>
      get(store, 'features_applicable.kiosk', false),
    );
    const checkStoreTypes = () => {
      active_stores?.filter((store: any) => {
        if (store?.store_type !== 'MLSEStoreType') {
          hasMLSEOnlyStore = false;
        }
        if (store?.store_type !== 'CartersStoreType') {
          hasCartersOnlyStore = false;
        }
        if (store?.store_type !== 'DubaiDutyFreeStoreType') {
          hasDDFOnlyStore = false;
        }
      });
      return {
        MLSEStoreType: hasMLSEOnlyStore,
        CartersStoreType: hasCartersOnlyStore,
        DubaiDutyFreeStoreType: hasDDFOnlyStore,
      };
    };
    const showEmailReport = checkStoreTypes()['CartersStoreType'];
    const showRevenueReport =
    checkStoreTypes()['CartersStoreType'] ||
    checkStoreTypes()['MLSEStoreType'] ||
    checkStoreTypes()['DubaiDutyFreeStoreType']
    ? true
    : false;
    const itemReport = checkStoreTypes()['MLSEStoreType'] || checkStoreTypes()['DubaiDutyFreeStoreType'] ? true : false;
    const showIncompleteOrdersReport = checkStoreTypes()['DubaiDutyFreeStoreType'];

    const defaultRoute = () => {
      if (hasScanAndGoStores) {
        return '/dashboard/orders';
      } else if (isClickAndCollect) {
        return '/dashboard/click_and_collect';
      } else if (isCashDesk) {
        return '/dashboard/cashDesk';
      } else if (hasKioskStores) {
        return '/dashboard/kiosk';
      } else if (hasPromoEnabled) {
        return '/dashboard/promos';
      }
    };

    if (path === '/dashboard/orders' && hasScanAndGoStores) {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    } else if (path === '/dashboard/orders' && !hasScanAndGoStores) {
      return <Redirect to={defaultRoute()} />;
    } else if (path === '/dashboard/click_and_collect' && isClickAndCollect) {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    } else if (path === '/dashboard/click_and_collect' && !isClickAndCollect) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/cashDesk' && isCashDesk) {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    } else if (path === '/dashboard/cashDesk' && !isCashDesk) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/revenue' && !showRevenueReport) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/emailReport' && !showEmailReport) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/promos' && !hasPromoEnabled) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/itemReport' && !itemReport) {
      return <Redirect to="/" />;
    } else if (
      path === '/dashboard/incompleteOrders' &&
      !showIncompleteOrdersReport
    ) {
      return <Redirect to="/" />;
    } else {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    }
  };
  return <Route {...rest} render={(props: any) => routeToScreens(props)} />;
};

export default PrivateRoute;
